import React from 'react';
import './App.css';

function Header() {
    return(
        <div className="page_header_spacer">
            {/* <p>The Book of Enoch</p>
                <div className="paragraph">
                    <p>Hi, im Enoch</p>
                    <p>a developer who is finding his way through this world filled with technology</p>
                    <p>I am a VR, AR, and Software Developer</p>
                    <p>welcome to my story...</p>
                </div> */}
        </div>
    )
}

export default Header;