import React from 'react';
import './App.css';

function NotFound404() {
    return(
        <div >
            <h3>404 Not Found!</h3>
        </div>
    )
}

export default NotFound404;